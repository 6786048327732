import { SelectionRange } from "@codemirror/state";
import { ViewUpdate } from "@codemirror/view";
import { createOptionalStore, useStore } from "@fscrypto/state-management";

export const codeEditorStore = createOptionalStore<ViewUpdate>();

export const getCodeEditorSelection = () => {
  const editor = codeEditorStore.get();
  if (!editor) return null;
  const currentSelection = getCurrentSelection(editor.state?.selection.ranges[0]);
  const selectionString = currentSelection
    ? editor.state?.doc.sliceString(currentSelection.from, currentSelection.to)
    : null;
  return selectionString;
};

export const useQueryCodeEditor = () => {
  const editor = useStore(codeEditorStore);
  if (!editor) return null;
  const currentSelection = getCurrentSelection(editor.state?.selection.ranges[0]);
  const selectionString = currentSelection
    ? editor.state?.doc.sliceString(currentSelection.from, currentSelection.to)
    : null;
  const addToEditor = (value: string) => {
    const selection = editor.state?.selection.ranges[0];
    if (!selection) return;
    editor.view?.dispatch({
      changes: {
        from: selection.from,
        to: selection.to,
        insert: value,
      },
    });
  };
  return {
    editor,
    addToEditor,
    selectionString,
  };
};

const getCurrentSelection = (selection?: SelectionRange) => {
  if (!selection) return null;
  const { from, to } = selection;
  if (from === to) return null;
  return { from, to };
};
